<header>
  <div class="container-fluid header-border">
    <div class="row">
      <ng-container>
        <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-4 zoom-400-margin">
          <div class="goldy-logo mt-logo">
            <img
              height="40"
              width="100"
              [src]="logo"
              data-automation-id="goldy-instructor-logo"
              [alt]="'GENERIC.LOGO' | translate" />
          </div>
        </div>
        @if (isShowNavigation) {
          <div
            class="col-md-8 col-lg-8 col-sm-8 col-xs-8 col-8 zoom-400-margin text-end">
            @if (
              (userRole === "instructor" && customAction === "launch") ||
              userRole === "learner"
            ) {
              <ng-container [ngTemplateOutlet]="learnerHeader"></ng-container>
            }
            @if (userRole === "instructor" && customAction === "select") {
              <ng-container
                [ngTemplateOutlet]="instructorHeader"></ng-container>
            }
          </div>
        }
        <hr aria-hidden="true" class="mt-2 mb-0" />
      </ng-container>
      @if (
        page === "QUESTION" ||
        (page === "RESULT_SUMMARY" &&
          maxAttempts === 1 &&
          ((userRole === "instructor" && customAction === "launch") ||
            userRole === "learner"))
      ) {
        <div
          class="fs-4 fw-semibold"
          [innerHTML]="chapterName"
          data-automation-id="go-chapter-name"></div>
      }
    </div>
  </div>
</header>

<ng-template #instructorHeader>
  @if (isTemplateCourse) {
    <ng-container [ngTemplateOutlet]="templateCourse"></ng-container>
  }

  @if (isLockedCourse) {
    <ng-container [ngTemplateOutlet]="lockedCourseTemplate"></ng-container>
  }
</ng-template>

<ng-template #learnerHeader>
  <div>
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="onSaveExit()"
      data-automation-id="exit-button">
      {{ "LEARNER.HEADER.EXIT_BUTTON" | translate }}
    </button>
    @if (showReadingButton) {
      <button
        type="button"
        class="btn btn-outline-primary ms-2"
        (click)="backToReading()"
        data-automation-id="back-to-reader-button">
        {{ "LEARNER.HEADER.READING" | translate }}
      </button>
    }

    @if (showResultsButton) {
      <button
        type="button"
        class="btn btn-primary ms-2"
        (click)="showResults()"
        [attr.data-automation-id]="
          isButtonTextAttempts
            ? 'back-to-attemps-button'
            : 'back-to-results-button'
        ">
        {{
          isButtonTextAttempts
            ? ("LEARNER.HEADER.ATTEMPTS" | translate)
            : ("LEARNER.HEADER.RESULTS" | translate)
        }}
      </button>
    }

    @if (showQuestionsButton) {
      <button
        type="button"
        class="btn btn-primary ms-2"
        (click)="backToQuestions()"
        [attr.data-automation-id]="
          isButtonTextResumeAttempt
            ? 'resume-attempt-button'
            : 'back-to-questions-button'
        ">
        {{
          isButtonTextResumeAttempt
            ? ("LEARNER.HEADER.RESUME_ATTEMPT" | translate)
            : ("LEARNER.HEADER.QUESTIONS" | translate)
        }}
      </button>
    }

    @if (showNewAttemptButton) {
      <button
        type="button"
        class="btn btn-primary ms-2"
        (click)="startNewAttempt()"
        data-automation-id="student-new-attempt-button">
        {{ "LEARNER.HEADER.NEW_ATTEMPT" | translate }}
      </button>
    }

    @if (showSubmitButton) {
      <button
        type="button"
        class="btn btn-primary ms-2"
        (click)="onSubmit()"
        data-automation-id="student-submit-btn">
        {{ "LEARNER.HEADER.SUBMIT_BUTTON" | translate }}
      </button>
    }
  </div>
</ng-template>

<ng-template #templateCourse>
  <div class="d-inline-block template-wrapper text-white fw-bold">
    <div class="d-inline-block template-wrapper__text">
      {{
        "INSTRUCTOR.SETTINGS_CONTENT_MODAL.GO_TEMPLATE.MARK_AS_TEMPLATE_INFO_MESSAGE"
          | translate
      }}
    </div>
    <button
      type="button"
      [ngbPopover]="templateCourseInfo"
      [attr.aria-label]="
        'INSTRUCTOR.SETTINGS_CONTENT_MODAL.GO_TEMPLATE.MARK_AS_TEMPLATE_TOOLTIP_MESSAGE'
          | translate
      "
      [ngbTooltip]="
        'INSTRUCTOR.SETTINGS_CONTENT_MODAL.GO_TEMPLATE.MARK_AS_TEMPLATE_TOOLTIP_MESSAGE'
          | translate
      "
      placement="bottom-right"
      #templateCourseTooltip="ngbTooltip"
      (click)="templateCourseTooltip.close()"
      data-automation-id="template-tooltip-icon"
      class="border-0 bg-transparent text-white">
      <mhicon-info-circle size="md"></mhicon-info-circle>
    </button>

    <ng-template #templateCourseInfo>
      <div class="template-course-info-popover">
        {{
          "INSTRUCTOR.SETTINGS_CONTENT_MODAL.GO_TEMPLATE.MARK_AS_TEMPLATE_POPOVER_MESSAGE"
            | translate
        }}
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #lockedCourseTemplate>
  <div class="d-inline-block locked-course-template text-end">
    <div class="d-inline-block locked-course-template__text fw-bold">
      {{ "INSTRUCTOR.LOCKED_COURSE.LOCKED_COURSE_TEXT" | translate }}
    </div>
    <button
      type="button"
      [ngbPopover]="lockedCourseTemplateInfo"
      [attr.aria-label]="
        'INSTRUCTOR.LOCKED_COURSE.LOCKED_COURSE_TOOLTIP' | translate
      "
      [ngbTooltip]="
        'INSTRUCTOR.LOCKED_COURSE.LOCKED_COURSE_TOOLTIP' | translate
      "
      #lockedCourseTooltip="ngbTooltip"
      (click)="lockedCourseTooltip.close()"
      placement="bottom-right"
      data-automation-id="locked-course-tooltip-icon"
      class="border-0 bg-transparent">
      <mhicon-info-circle size="md"></mhicon-info-circle>
    </button>

    <ng-template #lockedCourseTemplateInfo>
      <div class="locked-course-template-popover">
        {{ "INSTRUCTOR.LOCKED_COURSE.LOCKED_COURSE_MESSAGE" | translate }}
      </div>
    </ng-template>
  </div>
</ng-template>
