import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AttemptService } from 'src/app/shared/services/attempt.service';

@Component({
  selector: 'go-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  chapterName: string | null = '';
  logo: string = '../../../../assets/GO_Logo.svg';
  userRole!: string;
  customAction!: string;
  isLockedCourse: boolean = false;
  isTemplateCourse: boolean = false;
  showQuestionsButton: boolean = false;
  showNewAttemptButton: boolean = false;
  showReadingButton: boolean = false;
  showResultsButton: boolean = false;
  showSubmitButton: boolean = false;
  page: string = 'READING';
  isButtonTextResumeAttempt: boolean = false;
  maxAttempts: number = 0;
  isButtonTextAttempts: boolean = false;
  isShowNavigation: boolean = true;
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private attemptService: AttemptService
  ) {
    this.sharedService.courseAsTemplateEvent.subscribe(
      (isTemplateCourse: boolean) => {
        this.isTemplateCourse = isTemplateCourse;
      }
    );

    this.sharedService.learnerHeaderNavigationsEvent.subscribe(() => {
      this.learnerNavigations();
    });

    this.sharedService.getPageInfoEvent.subscribe((page: string) => {
      this.page = page;
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.includes('/unauthorized') ||
          event.url.includes('/error')
        ) {
          this.isShowNavigation = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.chapterName = this.sharedService.getAssignmentTitle();
    this.userRole = this.sharedService.getRole();
    this.customAction = this.sharedService.getLtiaAction();
    this.isLockedCourse = this.sharedService.getLockedCourse()!;
    this.maxAttempts = this.attemptService.getMaxAttempts();
    this.learnerNavigations();
  }

  learnerNavigations() {
    if (this.userRole === 'learner') {
      const attemptStatus = this.attemptService.getLatestAttemptStatus();
      const remainingAttempts = this.attemptService.getRemainingAttempts();
      const maxAttempts = this.attemptService.getMaxAttempts();
      const latestAttemptNo = this.attemptService.getLatestAttempts();
      this.showSubmitButton =
        (attemptStatus === 'IN_PROGRESS' || latestAttemptNo === 0) &&
        this.page === 'QUESTION';

      if (maxAttempts === 1) {
        this.showQuestionsButton =
          (attemptStatus === 'IN_PROGRESS' || latestAttemptNo === 0) &&
          this.page === 'READING';
        this.showResultsButton =
          attemptStatus === 'SUBMITTED' && this.page === 'READING';
        this.showReadingButton = this.page === 'QUESTION';
      } else if (maxAttempts! > 1) {
        this.showQuestionsButton =
          (attemptStatus === 'IN_PROGRESS' || latestAttemptNo === 0) &&
          this.page !== 'QUESTION' &&
          this.page !== 'RESULT_SUMMARY';
        this.showResultsButton =
          remainingAttempts < maxAttempts! && this.page !== 'RESULT_SUMMARY';
        this.showNewAttemptButton =
          attemptStatus === 'SUBMITTED' &&
          remainingAttempts > 0 &&
          this.page !== 'QUESTION' &&
          this.page !== 'RESULT_SUMMARY';
        this.showReadingButton =
          this.page === 'QUESTION' || this.page === 'RESULT_SUMMARY';
        this.isButtonTextResumeAttempt =
          attemptStatus === 'IN_PROGRESS' && this.page === 'RESULT_SUMMARY';
        this.isButtonTextAttempts =
          maxAttempts > 1 && this.page !== 'RESULT_SUMMARY';
      }
    } else if (
      this.userRole === 'instructor' &&
      this.customAction === 'launch'
    ) {
      this.showReadingButton = this.page === 'QUESTION';
      this.showQuestionsButton = this.page === 'READING';
      const paginationWrapper = document.getElementById(
        'pagination-wrapper'
      ) as HTMLElement;
      if (paginationWrapper) {
        this.page === 'QUESTION'
          ? paginationWrapper.classList.remove('hide')
          : paginationWrapper.classList.add('hide');
      }
    }
  }

  backToQuestions() {
    if (this.page === 'RESULT_SUMMARY') {
      this.router.navigate(['/ebook']);
    }
    this.sharedService.toggleQuestionReaderView(true);
    this.page = 'QUESTION';
    this.learnerNavigations();
  }

  backToReading() {
    if (this.page === 'RESULT_SUMMARY') {
      this.router.navigate(['/ebook']);
    }
    this.sharedService.toggleQuestionReaderView(false);
    this.page = 'READING';
    this.learnerNavigations();
  }

  onSaveExit() {
    this.sharedService.onSaveExitClick();
  }

  onSubmit() {
    this.sharedService.onSubmitClick('resultPage');
  }

  startNewAttempt() {
    if (this.page === 'RESULT_SUMMARY') {
      this.attemptService.setNewAttemptStarted(true);
      this.sharedService.toggleQuestionReaderView(true);
      this.page = 'QUESTION';
      this.learnerNavigations();
      this.router.navigate(['/ebook']);
    } else {
      this.page = 'QUESTION';
      this.attemptService.onClickNewAttempt();
    }
  }

  showResults() {
    if (this.attemptService.getMaxAttempts() === 1) {
      this.backToQuestions();
    } else {
      this.page = 'RESULT_SUMMARY';
      this.router.navigate(['/ebook/results']);
    }
  }
}
